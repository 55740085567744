import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next-translate-routes/router';

import { useCmsContent } from '@/contexts/CmsContentContext';
import appleTouchIconPng from '@/public/assets/faicon/apple-touch-icon.png';
import favicon16Png from '@/public/assets/faicon/favicon-16x16.png';
import favicon32Png from '@/public/assets/faicon/favicon-32x32.png';
import safariPinnedTabSvg from '@/public/assets/faicon/safari-pinned-tab.svg';
import { AppConfig } from '@/utils/AppConfig';

type IMetaProps = {
  title: string;
  description: string;
  keywords?: string;
  shouldBeIndexed?: boolean;
};

const Meta = ({
  title = AppConfig.site_name,
  description = 'Find and compare the best deposit accounts in the EU. Discover high interest deposit accounts, secure online deposit accounts, and learn about the Deposit Guarantee Scheme.',
  keywords,
  shouldBeIndexed = true,
}: IMetaProps) => {
  const router = useRouter();
  const { seoContent, preparedLocalizations } = useCmsContent();

  const getCanonicalHref = () => {
    if (router.locale === 'en') {
      return `https://${
        process.env.NEXT_PUBLIC_MAIN_DOMAIN || 'pickthebank.eu'
      }${router.asPath}`;
    }
    return `https://${
      process.env.NEXT_PUBLIC_MAIN_DOMAIN || 'pickthebank.eu'
    }/${router.locale}${router.asPath}`;
  };

  const getLocalizations = () => {
    return Object.values(preparedLocalizations)
      .filter((localization) => {
        return (
          localization.hrefLang !== router.locale &&
          (router.pathname === '/' || localization.href !== '/')
        );
      })
      .map((localization) => {
        return {
          href: `https://${
            process.env.NEXT_PUBLIC_MAIN_DOMAIN || 'pickthebank.eu'
          }${localization.href}`,
          hrefLang: localization.hrefLang,
        };
      });
  };

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={appleTouchIconPng.src}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={favicon32Png.src}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={favicon16Png.src}
        />
        <link rel="shortcut icon" href="/assets/faicon/favicon.ico" />
        <link rel="icon" href="/assets/faicon/favicon.ico" />
        <link rel="manifest" href="/assets/faicon/site.webmanifest" />
        <link rel="mask-icon" href={safariPinnedTabSvg.src} color="#326bff" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <NextSeo
        noindex={
          !shouldBeIndexed || process.env.NEXT_PUBLIC_ROBOTS_INDEX === 'false'
        }
        nofollow={
          !shouldBeIndexed || process.env.NEXT_PUBLIC_ROBOTS_INDEX === 'false'
        }
        title={seoContent?.title || title}
        description={seoContent?.description || description}
        canonical={seoContent?.canonical || getCanonicalHref()}
        openGraph={{
          title: seoContent?.title || title,
          description: seoContent?.description || description,
          url: seoContent?.canonical || getCanonicalHref(),
          locale: AppConfig.locale,
          site_name: AppConfig.site_name,
          images: [
            // TODO add meta images here
            {
              url:
                seoContent?.metaImage ||
                'https://i.ibb.co/sgDTGnm/1200-630.png',
              width: 1200,
              height: 630,
              alt: 'Og Image Alt Second',
              type: 'image/png',
            },
          ],
        }}
        languageAlternates={getLocalizations()}
        twitter={{
          cardType: 'summary_large_image', // twitter:card
          site: 'PickTheBank', // twitter:site
          handle: 'PickTheBank', // twitter:creator
        }}
        additionalMetaTags={[
          {
            property: 'twitter:description',
            content:
              seoContent?.description ||
              'Your ultimate resource for deposit rates in Europe.',
          },
          {
            property: 'keywords',
            content:
              seoContent?.keywords ||
              keywords ||
              'Best Deposit Accounts, High Interest Deposit Accounts, Compare Deposit Accounts, EU Deposit Accounts, Online Deposit Accounts, Secure Deposit Accounts, Deposit Guarantee Scheme',
          },
        ]}
      />
    </>
  );
};

export { Meta };
