import type { ReactNode } from 'react';
import React from 'react';

export const addPropsToReactElement = (
  element: ReactNode,
  props: React.Attributes | undefined,
) => {
  if (React.isValidElement(element)) {
    return React.cloneElement(element, props);
  }
  return element;
};

export const addPropsToChildren = (
  childrenElements: ReactNode,
  props: React.Attributes | undefined,
) => {
  if (!Array.isArray(childrenElements)) {
    return addPropsToReactElement(childrenElements, props);
  }
  return childrenElements.map((childElement) =>
    addPropsToReactElement(childElement, props),
  );
};
