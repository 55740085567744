import { useRouter } from 'next-translate-routes/router';

import { Icon } from '@/components';
import type { SvgProps } from '@/components/Sprite/Icon';
import { useModalsStore } from '@/store/modalsStore';
import { cn } from '@/utils';

const MobileBottomButton = ({
  title,
  icon,
  onClick,
  isActive,
  iconClassName,
}: {
  title: string;
  icon: SvgProps['name'];
  onClick: () => void;
  isActive?: boolean;
  iconClassName?: string;
}) => {
  return (
    <button
      type="button"
      className="caption flex h-[55px] w-[76px] flex-col items-center justify-center"
      onClick={onClick}
    >
      <Icon
        name={icon}
        className={cn(
          'size-8 text-grey-400',
          isActive && 'text-blue-500',
          iconClassName,
        )}
      />
      <span className={cn(isActive && 'text-blue-500')}>{title}</span>
    </button>
  );
};
export const MobileBottomBar = () => {
  const router = useRouter();
  const { setIsBurgerMenuOpen, isBurgerMenuOpen } = useModalsStore(
    (state) => state,
  );
  return (
    <div className="safe-area-bottom shadow-far fixed bottom-0 z-[49] flex w-full justify-between bg-white px-4">
      <MobileBottomButton
        title="Portfolio"
        icon="ptb-glyph"
        onClick={() => {
          setIsBurgerMenuOpen(false);
          router.replace('/profile/portfolio');
        }}
        isActive={
          router.pathname.includes('/profile/portfolio') && !isBurgerMenuOpen
        }
      />
      <MobileBottomButton
        title="All deposits"
        icon="search-icon"
        onClick={() => {
          setIsBurgerMenuOpen(false);
          router.replace('/multibanking-platform');
        }}
        isActive={
          router.pathname.includes('/multibanking-platform') &&
          !isBurgerMenuOpen
        }
      />
      <MobileBottomButton
        title="Favourites"
        icon="bookmark"
        onClick={() => {
          setIsBurgerMenuOpen(false);
          router.replace('/favourites');
        }}
        isActive={router.pathname.includes('/favourites') && !isBurgerMenuOpen}
      />
      <MobileBottomButton
        title="Other"
        icon="horizontal-dots"
        onClick={() => {
          setIsBurgerMenuOpen(true);
        }}
        isActive={isBurgerMenuOpen}
      />
    </div>
  );
};
